import { useState, useEffect } from 'react'
import { projectAuth } from '../firebase/config'
import { useAuthContext } from './useAuthContext'

export const useLogout = () => {
    const [isCancellled, setIsCancelled] = useState(false)
    const [error, setError] = useState(null)
    const [isPending, setIsPending] = useState(false)
    const { dispatch } = useAuthContext()

    const logout = async () => {
        setError(null)
        setIsPending(true)

        // sign the user out
        try {
            await projectAuth.signOut()

            // dispatch logout action
            dispatch({ type: 'LOGOUT' })

            // update state
            if (!isCancellled) {
                setIsPending(false)
                setError(null)
            }
        }
        catch (err) {
            if (!isCancellled) {
                console.log(err.messages)
                setIsPending(false)
                setError(null)
            }
        }
    }

    useEffect(() => {
        return () => setIsCancelled(true)
      }, [])

    return { error, isPending, logout }

}