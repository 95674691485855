import firebase from 'firebase/app'
import 'firebase/firestore'
import 'firebase/auth'

const firebaseConfig = {
    apiKey: "AIzaSyC3r5b0qT2CXZOSpvc0Ru-mQnsQ3myU52k",
    authDomain: "yourmoney-2bb1d.firebaseapp.com",
    projectId: "yourmoney-2bb1d",
    storageBucket: "yourmoney-2bb1d.appspot.com",
    messagingSenderId: "1095033381382",
    appId: "1:1095033381382:web:6d66fdc929ea32dac89987"
  }

//   init firebase
firebase.initializeApp(firebaseConfig)

// init service
const projectFirestore = firebase.firestore()
const projectAuth = firebase.auth()

// timestamp
const timestamp = firebase.firestore.Timestamp

export { projectFirestore, projectAuth, timestamp }